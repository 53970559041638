.bar-topo{
	background: $theme;
	color:#FFF;
	font-family: 'latoregular';

	a{
		color: #fff;
	}

	ul{
		list-style:none;
		padding-left:none;
		margin-bottom:0;

		.active a,
		a:hover{
			text-decoration:none;
			background: darken($theme,10%);
			color:#FFF;
		}
	}

	.btn{
		border-radius:0;
		display:flex;
		align-items:center;
	}

	@import "components/_buscas.scss";
	@import "components/_carrinho.scss";

	@include media-breakpoint-down(xs){
		text-align:center;
		padding-bottom:10px;

		.atendimento{
			padding:10px 0;
			border-bottom: 1px solid rgba(#000,0.2);
			margin-bottom:10px;
			width:100%;
		}

		ul{
			li{
				display: inline-block;

				& + li:before{
					content:'|';
					display: inline-block;
					margin:0 10px;
				}
			}

			a{
				display: inline-block;
				padding:5px;
			}
		}

		.user-ctrls{
			margin-bottom:10px;
			width:100%;
		}

		.buscas{
			display:flex;
			margin-right:10px;
			border:1px solid rgba(#000,0.2);
			flex-grow:1;

			.form-control{
				flex-grow:1;
				padding-left:10px;
				padding-right:10px;
			}
		}

		.btn-carrinho{
			margin-left:auto;
			margin-right:auto;
		}

		.d-flx{
			display:flex;
			flex-wrap:wrap;
		}
	}

	@include media-breakpoint-only(sm){
		.d-flx{
			display:flex;
			flex-wrap:wrap;
			padding-bottom:10px;
		}

		.atendimento{
			width:100%;
			padding:10px 0;
			text-align:center;
			border-bottom:1px solid rgba(#000,0.2);
			margin-bottom:10px;

			& ~ *{
				display:flex;
			}
		}

		.user-ctrls{
			margin-right:10px;
		}

		ul{
			&,
			& > li,
			& > li > a{
				display:flex;
			}

			& > li + li{
				margin-left:3px;
				position:relative;

				&:before{
					content:'';
					display: inline-block;
					height:1em;
					position:absolute;
					border-left:1px solid #FFF;
					right:100%;
					margin-right:1px solid #FFF;
					top:calc(50% - 0.5em);
				}
			}

			a{
				padding:10px;
			}
		}

		.buscas{
			border:1px solid rgba(#000,0.2);
			margin: 0 10px;
			flex-grow:1;

			.form-control{
				flex-grow:1;
				padding-left:10px;
				padding-right:10px;
			}
		}
	}

	@include media-breakpoint-only(md){
		.d-flx{
			justify-content:flex-end;
			padding-bottom:10px;
		}

		.atendimento{
			width:100%;
			padding:10px 0;
			border-bottom:1px solid rgba(#000,0.2);
			margin-bottom:10px;
		}

		.user-ctrls{
			margin-left:0 !important;
		}

		.buscas{
			flex-grow:1 !important;
			border:1px solid rgba(#000,0.2);

			.form-control{
				flex-grow: 1 !important;
				padding-left:10px;
				padding-right:10px;
			}
		}
	}

	@include media-breakpoint-only(lg){
		padding:10px 0;
		.buscas{
			border:1px solid rgba(#000,0.2);
		}
	}

	@include media-breakpoint-up(md){
		.d-flx{
			display:flex;
			flex-wrap:wrap;
		}

		.atendimento{
			max-width:100%;
			flex-grow:1;
			display:flex;
			align-items:center;

			&  ~ * {
				flex: 0 0 auto;
				width:auto;
				max-width:none;
				margin-left:10px;
			}
		}

		ul{
			&,
			& > li,
			& > li > a{
				display:flex;
			}

			& > li{
				position:relative;

				& + li{
					margin-left:3px;
					&:before{
						content:'';
						display: inline-block;
						height:1em;
						border-left:1px solid #FFF;
						position:absolute;
						right:100%;
						margin-right:1px;
						top: calc(50% - 0.5em);
					}
				}
			}

			a{
				padding:10px;
				align-items:center;
			}
		}

		.buscas{
			display:flex;
		}

		.btn-carrinho{
			display:flex;
		}
	}

	.btn{
		border-color:transparent;
	}


}

