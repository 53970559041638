body{
	font-family: 'latoregular';
}

a{
	color:inherit;
}

.paginacao{
	text-align: center;
}

.title-internas{
	margin-top:0;
}

.lt-blk{
	font-family: 'latoblack';
}

.lt-blk-it{
	font-family: 'latoblack_italic';
}

.lt-light{
	font-family: 'latolight';
}

.title-section{
	font-family: 'latoblack_italic';
	font-size:30px;
	color: #989898;
}

.ops-bld{
	font-family: 'open_sansbold';
}

@include text-emphasis-variant('.text-red', #FF083D);
@include text-emphasis-variant('.text-white', #FFF);
@include text-emphasis-variant('.text-blue', $theme);


.title-footer{
	margin-top:0;
	font-size:16px;
	font-weight:bold;
	color: #363636;
	margin-bottom:20px;
}

.paginacao{
	& > *{
		background: #A5C5EB;
		color: #3782D6;
		border-radius:4px;
	}

	.active,
	& > *:hover{
		background: #3782D6;
		color:#FFF;
	}
}

h1{
	font-size:20px;
	font-family: trebuchet, sans-serif;
	font-weight:bold;
}

.ops-bld{
	font-family: 'open_sansbold';
}
