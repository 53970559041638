.btn-theme{
	@include button-variant($theme,#FFF);
}

.btn-newsletter{
	@include button-variant(lighten(#00526A, 10%),#FFF);
	border-color: rgba(#000,0.2);

	&:hover{
		border-color: rgba(#000,0.2);
	}
}


.btn-outline-theme{
	@include button-outline-variant($theme,#FFF);
}

.btn-outline-darken-theme{
	@include button-outline-variant(darken($theme,10%),#FFF);
}

.btn-darken-theme{
	@include button-variant(darken($theme,10%),#FFF);
}

.btn-outline-white{
	@include button-outline-variant(#FFF, #000);
}
.btn-white{
	@include button-variant(#FFF, #000);
}

.btn-darkgrey{
	@include button-variant(#484848,#FFF);
}

.btn-float{
	border-radius:100%;
}

.btn-black{
	@include button-variant(#000,#000);
	color: #FFF;

	&:hover{
		background: lighten(#000, 10%);
		color: #FFF;
	}
}