@import "_bar-topo.scss";

.topo{
	font-size:14px;

	.brand{
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.container-fluid{
		max-width:1170px;
	}

	figure{
		margin-bottom:0;
	}

	ul{
		list-style:none;
		padding-left:0;
		margin-bottom:0;
	}
}

@import "components/_menu.scss";
