.card-diabetes{
	max-width: 262px;
	margin-left: auto;
	margin-right: auto;
	border:none;

	.foto{
		margin-bottom:0;
		position: relative;
		overflow:hidden;

		&:after{
			content:'';
			width: 100%;
			height:25%;
			bottom:0;
			left:0;
			@include gradient-y(transparent, rgba(#000,0.4));
			position:absolute;
			display: block;
		}
	}

	.card-body{
		border-left:1px solid rgba(#000,0.2);
		border-right:1px solid rgba(#000,0.2);
		border-bottom:1px solid rgba(#000,0.2);
		transition: border 0.3s  ease-in-out;
	}

	img{
		transition: all 0.3s linear;
	}

	.foto{
		overflow: hidden;
	}

	&:hover{
		img{
			transform: scale(1.08)
		}
	}

	.card-title,
	a{
		color: #007ABD;
	}

	.card-title{
		font-size: 18px;
	}

	a{
		@extend .btn, .btn-primary, .btn-sm;
		color: #FFF;

		&:hover{
			color: #FFF;
		}
	}
}

.compartilhe{
	max-width: 570px;
	border-top:1px dotted rgba(#000,0.2);
	border-bottom:1px dotted rgba(#000,0.2);
	padding:0.5rem 0;
	color: #444444;

	& > *{
		display: inline-block;
		margin-right: 15px;
	}
}

.media-downloads{
	color: #535353;
	position: relative;
	padding: 10px 0;
	margin-bottom:0;

	&:after{
		content:'';
		display: block;
		width:570px;
		max-width:100%;
		border-bottom:1px solid rgba(#000,0.2);
		position: absolute;
		bottom:0;
		left:0;
	}

	img{
		margin-right: 10px;
	}

	.media-title{
		margin-bottom:0;
		font-size: 1em;
		font-weight:bold;
	}

	a:hover{
		text-decoration: none;
	}
}