.menu-bar{
	font-family: 'latoregular';

	.bg-menuOpened{
		position:fixed;
		top:0;
		left:0;
		width:100%;
		height:100%;
		background: rgba(#000,0.8);
		z-index:998;
		display: none;
	}

	.brand{
		max-width:230px;
	}

	.mobile-ctrls{
		border-bottom:1px solid rgba(#000,0.2);

		.btn{
			border-radius:0;
			padding-left:20px;
			padding-right:20px;
		}

		.brand{
			padding-left:15px;
		}
	}

	@include media-breakpoint-down(sm){
		.mobile-ctrls{
			display:flex;
			justify-content:space-between;

			.brand{
				width:180px;
			}
		}
	}

	@include media-breakpoint-only(md){
		.mobile-ctrls{
			display:flex;
			justify-content:space-between;
		}
	}

	@include media-breakpoint-only(lg){
		.mobile-ctrls{
			display:flex;
			justify-content:space-between;
		}
	}

	@include media-breakpoint-down(lg){
		&.open .content{
			left:0;
		}

		.content{
			position:fixed;
			left:-250px;
			top:0;
			z-index:1000;
			width:220px;
			height:100%;
			background: #FFF;
			border-right:1px solid #CCC;
			transition:left 0.6s linear;
			overflow:auto;

			.brand{
				padding-top:30px;
				padding-bottom:10px;
				padding-left:15px;
				padding-right:15px;
				border-bottom:1px solid #CCC;
			}
		}

		.login-area{
			display:flex;
			padding:10px 15px;
			align-items:center;
			border-bottom:1px solid #CCC;

			svg{
				margin-right:10px;
			}

			.text-block{
				width:calc(100% - 40px);
			}
		}

		.menu {
			padding-bottom:20px;
			.active a,
			.active a:hover{
				background: $theme;
				color:#FFF;
			}

			a{
				display: block;
				padding:10px 15px;
				border-bottom: 1px solid rgba(#000,0.2);
				&:hover{
					text-decoration:none;
					background: #eee;
				}
			}
		}
	}

	&.fixed{
		position:fixed;
		z-index:1000;
		box-shadow:0 0 8px rgba(#000,0.4);
		width:100%;
		top:0;
		left:0;
		background:#FFF;
	}

	@include media-breakpoint-up(xl){

		.mobile-ctrls{
			display: none;
		}

		.content{
			max-width:1170px;
			padding-left:15px;
			padding-right:15px;
			margin-left:auto;
			margin-right:auto;
			display:flex;
		}

		.brand{
			order:1;
		}

		.login-area{
			order:3;
			display:flex;
			align-items:center;
			flex:auto;
			min-width:130px;

			svg{
				margin-right:10px;
			}

			.text-block{
				width:calc(100% - 40px);
			}
		}

		.menu{
			display:flex;
			flex-grow:1;
			padding-left:30px;
			padding-right:30px;
			order:2;

			ul{
				&,
				& > li,
				& > li > a{
					display:flex;
				}

				justify-content:space-between;
				width:100%;

				a{
					align-items:center;
					padding:5px;
					white-space:nowrap;
					font-size: 11px;
					color: #000;
					&:hover{
						text-decoration:none;
						background: #eee;
					}
				}

				.active a,
				.active a:hover{
					color: $theme;
				}
			}
		}
	}
}
