@font-face {
    font-family: 'latoregular';
    src: url('#{$fonts}/lato_regular/lato-regular-webfont.eot');
    src: url('#{$fonts}/lato_regular/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/lato_regular/lato-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato_regular/lato-regular-webfont.woff') format('woff'),
         url('#{$fonts}/lato_regular/lato-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/lato_regular/lato-regular-webfont.svg#latoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latoblack_italic';
    src: url('#{$fonts}/lato_black_italic/lato-blackitalic-webfont.eot');
    src: url('#{$fonts}/lato_black_italic/lato-blackitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/lato_black_italic/lato-blackitalic-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato_black_italic/lato-blackitalic-webfont.woff') format('woff'),
         url('#{$fonts}/lato_black_italic/lato-blackitalic-webfont.ttf') format('truetype'),
         url('#{$fonts}/lato_black_italic/lato-blackitalic-webfont.svg#latoblack_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latolight';
    src: url('#{$fonts}/lato_light/lato-light-webfont.eot');
    src: url('#{$fonts}/lato_light/lato-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/lato_light/lato-light-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato_light/lato-light-webfont.woff') format('woff'),
         url('#{$fonts}/lato_light/lato-light-webfont.ttf') format('truetype'),
         url('#{$fonts}/lato_light/lato-light-webfont.svg#latolight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latoblack';
    src: url('#{$fonts}/lato_black/lato-black-webfont.eot');
    src: url('#{$fonts}/lato_black/lato-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/lato_black/lato-black-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato_black/lato-black-webfont.woff') format('woff'),
         url('#{$fonts}/lato_black/lato-black-webfont.ttf') format('truetype'),
         url('#{$fonts}/lato_black/lato-black-webfont.svg#latoblack') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sansbold';
    src: url('#{$fonts}/opensans-bold/opensans-bold-webfont.eot');
    src: url('#{$fonts}/opensans-bold/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/opensans-bold/opensans-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/opensans-bold/opensans-bold-webfont.woff') format('woff'),
         url('#{$fonts}/opensans-bold/opensans-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/opensans-bold/opensans-bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;

}