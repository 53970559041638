.paginacao{
	margin-top:20px;
	margin-bottom:30px;

	> *{
		display: inline-block;
		border:1px solid rgba(#000,0.2);
		padding:6px 12px;
		background:#fff;
	}

	.active,
	*:hover{
		$color-default:#fff;
		text-decoration:none;
		background: materialColor('blue', 'blue');
		color: $color-default;
	}
}
