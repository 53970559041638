.noround{
    border-radius:0;
}

.img-fluid{
    display: inline-block;
}

.img-responsive{
    @extend .img-fluid;
}

svg{
    border:none;
    margin:0;
}

@import "_busttons.scss";


#msg-ie{
    margin-bottom:0;

    .alert-danger{
        margin-bottom:0;
        background: materialColor('red', 'darken-2');
        color:#FFF;

        a{
            color: #fff;
        }
    }
}

.lock-scroll{
    overflow:hidden;
}

.picture{
    position:relative;
    overflow:hidden;

    img,
    .caption-text{
        transition:all 0.3s linear;
    }

    &:hover{
        img{
            transform:scale(1.1);
        }

        .caption-text{
            background: rgba(#00B0ED, 0.8);
        }
    }

    .caption-text{
        padding:10px;
        position:absolute;
        background:rgba(#000,0.6);
        width:100%;
        height:100%;
        top:0;
        left:0;
        color:#FFF;
        display:flex;
        align-items:center;
        justify-content:center;
        text-align:center;
        font-size:18px;
        font-family: 'latoblack_italic';
        text-shadow:0 0 2px rgba(#000,0.4);
    }
}

.parallax{
    background-position:center center;
    background-attachment:fixed;
    background-size:cover;
}

@include media-breakpoint-down(md){
    .visible-desktop{
        display: none;
    }
}

#album{
    .next-album,
    .prev-album{
        display: none;
    }

    .carousel-controls{
        display: flex;
        align-items: center;
        justify-content: center;
        padding:6px 12px;
        background: #007EB2;
        color: #FFF;

        &:hover{
            color: #FFF;
            background: darken(#007EB2, 10%);
            text-decoration: none;
        }
    }

    .carousel-item{
        padding-left:15px;
        padding-right:15px;
    }

    @include media-breakpoint-down(md){
        .fa-chevron-up,
        .fa-chevron-down{
            display: none;
        }

        display: flex;

        .carousel-inner{
            flex-grow: 1;
        }

        .carousel-controls{
            display: block;
        }
    }

    @include media-breakpoint-up(lg){

        // max-width: 100px;
        margin-left:auto;
        margin-right:auto;

        .carousel-inner{
            height: 100%;
            min-height: 520px;
        }

        .carousel-item{
            .col-4{
                padding-top: 15px;
                padding-bottom: 15px;
            }
        }

        .fa-chevron-left,
        .fa-chevron-right{
            display: none;
        }

        .next-album{
            display: block;
        }

        &.carousel-vertical{

            .carousel-item-next.carousel-item-left,
            .carousel-item-prev.carousel-item-right {
              transform: translateX(0);

              @supports (transform-style: preserve-3d) {
                transform: translate3d(0, 0, 0);
              }
            }

            .carousel-item-next,
            .active.carousel-item-right {
              transform: translateX(100%);

              @supports (transform-style: preserve-3d) {
                transform: translate3d(0, 100%, 0);
              }
            }

            .carousel-item-prev,
            .active.carousel-item-left {
              transform: translateX(-100%);

              @supports (transform-style: preserve-3d) {
                transform: translate3d(0, -100%, 0);
              }
            }

        }
    }
}