.shadow-box{
	padding:30px 15px;
	box-shadow:0 0 15px rgba(#000,0.4);
}

.bread-crumbs{
	background: #A8A8A8;
	margin-bottom:40px;

	ol{
		list-style:none;
		padding-left:0;
		margin-bottom:0;
		color:#FFF;

		a,
		span{
			display: inline-block;
			padding:10px;
		}

		a:hover,
		span{
			color:#000;
		}

		li{
			display: inline-block;

			& + li:before{
				content:'/';
				display: inline-block;
				padding:10px;
			}
		}
	}
}

.vitrine-categorias{
	@include media-breakpoint-between(sm,lg){
		.col-lg-4:nth-child(2) ~ .col-lg-4{
			border-top:10px solid #e3e3e3;
			padding-top:30px;
		}
	}

	@include media-breakpoint-up(lg){
		.col-lg-4:nth-child(3) ~ .col-lg-4{
			border-top:10px solid #e3e3e3;
			padding-top:30px;
		}
	}
}

.sidebar{
	margin-bottom:30px;

	a{
		color: inherit;

		&:hover{
			color: inherit;
		}
	}

	.sidebar-head{
		padding:10px 15px;
		background: $theme;
		color:#FFF;
		text-align:center;
	}

	.sidebar-body{
		padding:10px 0;
	}

	.sidebar-section{
		& + .sidebar-section{
			margin-top: 30px;
		}
	}

	.title-sidebar{
		font-size:15px;
		padding:10px 15px;
		font-weight:bold;
	}

	.categorias{
		list-style:none;
		padding-left:0;

		.badge{
			color: #7C7F82;
		}

		a{
			display: block;
			padding:5px 15px;

			&:hover{
				background: #e3e3e3;
				color:#000;
				text-decoration:none;
			}
		}

		.active > a,
		.active > a:hover{
			background: #007db8;
			color:#FFF;
			.badge{
				color: #FFF;
			}
		}

		.collapse{
			// border-left:1px solid #ccc;
			// border-right:1px solid #ccc;
			// border-bottom:1px solid #ccc;
			background:#eee;
		}
	}

	.filtro{
		padding:0 15px;

		label{
			display: block;

			span{
				color:#999;
			}

			input[type="radio"]:checked + span{
				color:#000;
			}

			.badge{
				color:#000;
			}
		}
	}
}

.page-header{
	.dropdown-menu{
		span{
			display: block;
			padding:5px 10px;
			cursor:pointer;

			&:hover{
				background: #e3e3e3;
			}
		}

		.active span,
		.active span:hover{
			background: $theme;
			color:#FFF;
			cursor:default;
		}
	}

	.row{
		.first{
			@include media-breakpoint-down(sm){
				width:100%;
				text-align:center;
			}
		}
	}
}

.login-area{
	form{
		max-width:320px;
		margin-left:auto;
		margin-right:auto;

		h2{
			font-size:18px;
			margin-bottom:30px;
		}
	}

	@include media-breakpoint-up(md){
		.col-md-6{
			&:first-child{
				border-right:1px solid #CCC;
			}

			&:last-child{
				border-left:1px solid #CCC;
			}
		}
	}
}

.modal{
	.modal-content{
		border-radius:0;
	}

	&.modal-success{
		.modal-header{
			color:#FFF;
			background: materialColor('green', 'darken-2');
		}
	}

	&.modal-primary{
		.modal-header{
			color:#FFF;
			background: materialColor('blue', 'darken-2');
		}
	}

	&.modal-warning{
		.modal-header{
			color:#FFF;
			background: materialColor('warning', 'darken-2');
		}
	}

	&.modal-danger{
		.modal-header{
			color:#FFF;
			background: materialColor('red', 'darken-2');
		}
	}
}

@mixin message-validation-variant($color: #fff, $theme: materialColor('grey','grey')){
	background: $theme;
	color:$color;

	&:before{
		border-bottom-color: $theme;
	}
}

.form-control{
	border-radius:0;
}

.message-validation{
	position:relative;
	padding:10px;
	color:#fff;
	margin-top:10px;
	background: materialColor('grey','darken-1');

	&:before{
		content:'';
		display: inline-block;
		border-left:10px solid transparent;
		border-right:10px solid transparent;
		border-bottom:10px solid  materialColor('grey','darken-1');
		position: absolute;
		bottom:100%;
		left:10px;
	}
}

.has-success{
	label{
		color: materialColor('green', 'darken-2');
	}

	.form-control{
		border-color: materialColor('green', 'darken-2');
	}

	.message-validation{
		@include message-validation-variant(#FFF, materialColor('green', 'darken-2'));
	}
}

.has-error{
	label{
		color: materialColor('red', 'darken-2');
	}

	.form-control{
		border-color: materialColor('red', 'darken-2');
	}

	.message-validation{
		@include message-validation-variant(#FFF, materialColor('red', 'darken-2'));
	}
}

.has-warning{
	label{
		color: materialColor('orange', 'darken-2');
	}

	.form-control{
		border-color: materialColor('orange', 'darken-2');
	}

	.message-validation{
		@include message-validation-variant(#FFF, materialColor('orange', 'darken-2'));
	}
}

.has-info{
	label{
		color: materialColor('blue', 'darken-2');
	}

	.form-control{
		border-color: materialColor('blue', 'darken-2');
	}

	.message-validation{
		@include message-validation-variant(#FFF, materialColor('blue', 'darken-2'));
	}
}

fieldset{
	margin-bottom:60px;
	legend{
		margin-bottom:40px;

		h2{
			font-size:17px;
			font-weight:bold;
			font-family: trebuchet, sans-serif;
		}

		p{
			font-size:15px;
		}
	}
}

.table{
	.v-top{
		vertical-align:top;
	}
	.v-center{
		vertical-align:middle;
	}
}

.btn-custon-radio{
	display: inline-flex;
	color:#777;
	white-space:normal;
	text-align:left;

	span{
		cursor:pointer;
	}

	input{
		position:fixed;
		top:-100%;
	}

	.circle{
		display: inline-block;
		width:16px;
		height:16px;
		border:2px solid #777;
		border-radius:100%;
		position:relative;

		&:before{
			content:'';
			display: block;
			width:100%;
			height:100%;
			border:2px solid #FFF;
			background: $theme;
			border-radius:100%;
			position:absolute;
			top:0;
			left:0;
			transform:scale(0);
			transition:all 0.3s linear;
		}

		& + span{
			display: block;
			width: calc(100% - 16px);
			padding-left:7px;
			line-height:1.4;
		}
	}


	input:checked ~ span{
		color:#000;
	}

	input:checked + .circle,
	&.active .circle{
		border-color: $theme;

		&:before{
			transform:scale(1);
		}
	}
}

.btn-custon-check{
	display: inline-flex;
	color:#777;
	white-space:normal;

	span{
		cursor:pointer;
	}

	input{
		position:fixed;
		top:-100%;
	}

	.circle{
		display: inline-block;
		width:16px;
		height:16px;
		margin-right:7px;
		border:2px solid #777;
		border-radius:100%;
		position:relative;

		&:before{
			text-align:center;
			color:materialColor('green','darken-2');
			display: block;
			width:100%;
			height:100%;
			font-size:0.7em;
			text-align:center;
			line-height:12px;
			border-radius:100%;
			position:absolute;
			top:0;
			left:0;
			transform:scale(0) rotate(180deg);
			transition:all 0.3s linear;
		}

		& + span{
			display: block;
			width: calc(100% - 16px);
			padding-left:7px;
			line-height:1.4;
			text-align:left;
		}
	}


	input:checked ~ span{
		color:#000;
	}

	input:checked + .circle,
	&.active .circle{
		border-color: materialColor('green','darken-2');

		&:before{
			transform:scale(1) rotate(0deg);
		}
	}
}

form{
	.btn{
		white-space:normal;
	}
}

#responsaveis{
	.responsavel{
		& + .responsavel{
			padding-top:30px;
			margin-top:15px;
			border-top:1px dotted #ccc;
		}

		.add-remove{
			display: none;
		}

		.remove-responsavel{
			display: none;
		}

		&.remove-add-ctrl{
			.add-resp{
				display: none;
			}
		}
	}
}

#confirma-bag{
	.media-body{
		padding-left:30px;
	}
}

.card-head{
	padding:10px;
}

.card-confirma-compra{
	.card-head{
		background: $theme;
		color:#FFF;
		font-weight:bold;
	}

	@include media-breakpoint-down(lg){
		flex-wrap:wrap;

		.card{
			width:100%;
			flex:0 auto;
			border-radius:0;

			& + .card{
				border-left:1px solid #e3e3e3;
			}
		}
	}
}

.nav-tabs{
	svg{
		display: block;
		margin-left:auto;
		margin-right:auto;
		margin-bottom:10px;
	}

	a.active svg use{
		fill: $theme;
	}
}

.tabs-pagamentos{
	border:1px solid #CCC;

	.nav-tabs{
		background: #EDEDED;
		border:none;
		display:flex;

		li{
			flex:1;
			display:flex;

			a{
				width:100%
			}

			& + li{
				border-left:1px solid #ccc;
			}

			&.empit{
				border-bottom:1px solid #ccc;

				@include media-breakpoint-down(sm){
					display: none;
				}
			}
		}
	}
	.nav-link{
		border-radius:0;
		border:none;
		border-bottom:1px solid #ccc;
		text-align:center;

		&.active{
			border-color:#FFF;
		}
	}

	.tab-pane{
		padding:15px;
	}

	form{
		max-width:600px;
		margin-left:auto;
		margin-right:auto;
	}
}

.table-carrinho{
	font-size:14px;

	border:1px solid $theme;
	border-bottom:1px solid #000;

	.remove-button{
		position:absolute;
		z-index:1;
	}

	& > thead > tr > th{
		background: $theme;
		color:#FFF;
	}

	& > tbody > tr > td{
		position:relative;

		&:last-child{
			font-size:20px;
			vertical-align:middle;
		}

		.prices{
			vertical-align:middle;
		}

		& + td{
			border-left:5px solid #eee;
		}
	}

	.media{
		margin-top:10px;
		margin-left:10px;
		.media-body{
			padding-left:10px;
		}

		lazyimage{
			width:100px;
			padding:4px;
			border:1px solid #ccc;
		}
	}

	.qtde-produtos{
		width:180px;
		margin-left:auto;
		margin-right:auto;

		.btn{
			border-radius: 0 !important;
		}

		.form-control{
			border-color:$theme;
		}

		.message-validation{
			&:before{
				left:50%;
				margin-left:-10px;
			}

			font-size:12px;

			.btn{
				border-radius:100% !important;
				padding:5px 10px;
				font-size:12px;
			}

			h4{
				font-size:16px;
			}
		}
	}
}


#calculeFrete{
	padding-top:20px;

	.border-left-2{
		padding:0 15px;
		margin-bottom:10px;
	}

	.form-flex{
		display:flex;
		align-items:flex-start;

		.form-group{
			width:100%;
			padding-right:15px;
		}
	}
}

.card-my-ctrls{
	max-width:400px;
	margin-left:auto;
	margin-right:auto;

	.card-head{
		display:flex;
		background: $theme;
		color:#FFF;
		padding:0 5px;
		border-bottom:1px solid rgba(#000,0.2);

		svg use{
			fill: #FFF;
		}

		.icon-area,
		.lgout{
			flex:auto;
			display:flex;
			padding:10px 5px;
			align-items:flex-end;
		}

		.msg{
			width:100%;
			padding:10px 5px;
		}

		a{
			white-space:nowrap;

			.fa{
				margin-right:7px;
			}

			&:hover{
				color:#FFF;
				text-decoration:none;
				background: rgba(#000,0.2);
			}
		}
	}

	ul{
		list-style:none;
		padding-left:0;
		margin-bottom:0;

		a{
			display: block;
			padding:10px 15px;
		}

		li+ li{
			border-top:1px solid rgba(#000,0.15);
		}

		a:hover{
			background: #eee;
			text-decoration:none;
		}

		.active a,
		.active a:hover{
			background: $theme;
			color:#FFF;
			text-decoration:none;
		}

	}
}

#form-meusDados{
	@include media-breakpoint-up(lg){
		label.col-lg-3{
			text-align:right;
		}
		label{
			margin-bottom:0;
		}

		.form-group.col,
		.form-group.col-lg-6,
		.form-group.col-lg{
			display:flex;
			align-items:center;

			label{
				padding:0 15px;
			}
		}

		.form-group.col-lg-6{
			label{
				text-align:right;
			}

			& > *{
				flex:1 !important;
			}
		}
	}
}

.nav-pedidos{
	color: #666;
	font-size:14px;

	ul{
		list-style:none;
		padding:0;
		border:1px solid #ccc;
		display:flex;
		justify-content:space-around;
		// flex-wrap:wrap;

		text-align:center;

		li{
			position:relative;
			top:-2.5px;
			margin-bottom:-2.5px;
			display:flex;
		}
	}

	a{
		display: block;
		width:100%;
		padding:10px 15px;
		border-top:5px solid transparent;

		&:hover{
			text-decoration:none;
			border-color: $theme;
			background:#EEE;
		}
	}

	.active a{
		background:#EEE;
		border-color: $theme;
	}
}

.btn-continuar-comprando{
	@include button-variant(#e3e3e3,#000);
	border-color:#e3e3e3;
	display:inline-flex;
	align-items:center;

	.fa{
		margin-right:7px;
	}
}

.bg-grey{
	background: #222222;
}

.bg-light-grey{
	background: #e9e9e9;
}
.card{
	border-radius:0;
}

.carousel-produtos{
	@include media-breakpoint-up(sm){
		.owl-nav{
			position:absolute;
			bottom:100%;
			right:0;
			margin-bottom:20px;
		}
	}
	.owl-prev,
	.owl-next{
		border-radius:100%;
		@extend .btn, .btn-primary;
		margin:10px 5px;
	}
}

@mixin pedido-variant($bgLighten,$bgDarken,$colorLighten,$colorDarken){
	.lighten{
		background: $bgLighten;
		color: $colorLighten;
	}

	.darken{
		background: $bgDarken;
		color:$colorDarken;
	}
}

.pedidos{

	h2{
		font-size:20px;
		margin-bottom:30px;
		overflow:hidden;
		padding:10px 0;
		color:#666666;
		text-align:right;

		span{
			position:relative;
			display: inline-block;

			&:before{
				content:'';
				display: block;
				width:1000px;
				right:100%;
				margin-right:15px;
				border-top:1px solid #CCC;
				top:50%;
				position:absolute;
			}
		}
	}

}

.pedido{
	display:flex;
	margin-bottom:30px;
	border:1px solid rgba(#000,0.2);
	text-align:center;
	box-shadow:0 0 10px rgba(#000,0.4);

	& > *{
		width:100%;
	}

	.cover{
		padding:15px;
		text-align:center;

		lazyimage{
			display: inline-block;
			padding:4px;
			border:1px solid #ccc;
		}
	}

	figcaption{
		padding:15px;
		text-align:center;
	}

	.lighten,
	.darken{
		padding:15px;
	}

	.lighten{
		background: materialColor('grey', 'grey');
	}

	.darken{
		background: materialColor('grey','darken-2');
		color:#FFF;
	}

	&.success{
		@include pedido-variant(materialColor('green','green'),materialColor('green','darken-2'), #FFF, #FFF)
	}

	&.error{
		@include pedido-variant(materialColor('red','red'),materialColor('red','darken-2'), #FFF, #FFF)
	}

	&.warning{
		@include pedido-variant(materialColor('orange','orange'),materialColor('orange','darken-2'), #FFF, #FFF)
	}

	&.info{
		@include pedido-variant(materialColor('blue','blue'),materialColor('blue','darken-2'), #FFF, #FFF)
	}

	.dia{
		@extend .ops-bld;
		font-size:2em;
	}

	.mes-ano,
	.entraga{
		@extend .ops-bld;
	}

	@include media-breakpoint-down(xs){
		flex-wrap:wrap;
	}

	@include media-breakpoint-only(sm){


		.status{
			border-top:2px solid #FFF;
			padding-top:15px;
		}

		.data{
			border-bottom:2px solid #FFF;
			padding-bottom:15px;
		}
	}

	@include media-breakpoint-only(md){
		.block-a,
		.block-b{
			display:flex;

			& > *{
				flex:1;
			}
		}

		.block-a{
			align-items:center;
		}

		.status{
			border-top:2px solid #FFF;
			padding-top:15px;
		}

		.data{
			border-bottom:2px solid #FFF;
			padding-bottom:15px;
		}
	}

	@include media-breakpoint-up(lg){
		.block-a,
		.block-b{
			display:flex;
		}

		.block-a{
			max-width: 33.333%;
		}

		.lighten{
			width:66.66%;
			display:flex;
			align-items:center;
		}

		.data-status{
			width:100%;

			display:flex;

			& > *{
				flex:1;
			}
		}

		.data{
			border-right:2px solid #FFF;
			padding-right:15px;
		}

		.status{
			border-left:2px solid #FFF;
			padding-left:15px;
		}

		.darken{
			width:33.33%;
		}
	}
}
@mixin pedido-detalhe-variant($bg,$color){
	.status{
		background: materialColor($bg,$bg);
		color:$color;
	}
	.data{
		background: materialColor($bg,'darken-2');
		color:$color;
	}
}

.detalhe-pedido{
	box-shadow:0 0 8px rgba(#000,0.4);

	&.default{
		@include pedido-detalhe-variant('grey',#FFF);
	}
	&.info{
		@include pedido-detalhe-variant('blue',#FFF);
	}
	&.warning{
		@include pedido-detalhe-variant('orange',#FFF);
	}
	&.danger{
		@include pedido-detalhe-variant('red',#FFF);
	}
	&.success{
		@include pedido-detalhe-variant('green',#FFF);
	}

	header{
		display:flex;
		font-size:14px;

		.h4{
			font-size:18px;
			@extend .ops-bld;
		}

		& > *{
			padding: 10px;
			width:100%;
		}
	}

	.detalhe-pedido-body{
		padding:15px;
		border-left:1px solid #CCC;
		border-right:1px solid #CCC;
		border-bottom:1px solid #CCC;
	}
}

.passos{
	list-style:none;
	padding-left:0;
	display:flex;

	li{
		position:relative;
		&:before{
			content:'';
			z-index:0;
		}

	}

	.active{
		.circle{
			background: $theme;
			color:#FFF;
			width:60px;
			height:60px;

			.fa{
				display: inline-block;
			}
		}
	}
	.active  ~ li{

		.circle {
			background: $theme;
		}
	}


	.active ~ li:before{
		background: $theme;
	}

	.circle{
		width:30px;
		height:30px;
		border-radius:100%;
		display:flex;
		align-items:center;
		justify-content:center;
		background: #e3e3e3;
		position:relative;
		z-index:1;

		.fa{
			display: none;
		}
	}

	@include media-breakpoint-down(md){
		flex-direction: column-reverse;
		font-size:14px;

		li{
			width:100%;
			display:flex;
			align-items:center;
			padding:10px 0;

			.circle{
				margin-left:15px;
				margin-right:35px;
			}

			&.active .circle{
				margin-left:0;
				margin-right:20px;
			}

			&.active:before,
			&.active ~ li:before{
				width:6px;
				height:100%;
				top:50%;
				position:absolute;
				left:27px;
			}

			&.active:before{
				background:#e3e3e3;
			}
		}

		.desc{
			padding:5px;
			border:1px solid #ccc;
			border-radius:4px;
			position:relative;

			&:before,
			&:after{
				content:'';
				display: block;
				position:absolute;
			}

			&:before{
				border-top:12px solid transparent;
				border-bottom:12px solid transparent;
				border-right:12px solid #ccc;
				top:calc(50% - 12px);
				right:100%;
			}

			&:after{
				border-top:12px solid transparent;
				border-bottom:12px solid transparent;
				border-right:12px solid #FFF;
				top:calc(50% - 12px);
				right:calc(100% - 1px);
			}
		}
	}

	@include media-breakpoint-up(lg){
		flex-direction: row-reverse;
		justify-content:space-between;
		font-size:12px;
		text-align:center;

		li{
			flex:1;

			&:before{
				width:calc(100% - 30px);
				position:absolute;
				right:calc(-50% + 15px);
				height:6px;
				background: #e3e3e3;
				top:27px;
			}

			&:first-child{
				&:before{
					display: none;
				}
			}
		}

		.circle{
			margin-top:15px;
			margin-left:auto;
			margin-right:auto;
			margin-bottom:25px;
		}

		.active{
			.circle{
				margin-top:0;
				margin-bottom:10px;
			}
		}
	}
}

.forma-de-pagamento{
	border:1px solid #CCC;
	box-shadow:0 0 8px rgba(#000,0.4);

	.pd-15{
		padding:15px;
	}

	tr.active > td,
	tr.active > th{
		background: #F2F2F2;
	}

	h4{
		font-size:20px;
	}

	.fa,
	.icon{
		margin-right:10px;
	}
}

.card-sobre-nos{
	margin-bottom:30px;
	font-size:16px;

	h2{
		font-size:20px;
		font-weight:bold;
		color:$theme;
		border-left:5px solid $theme;
		overflow:hidden;
		padding-left:30px;
		margin-bottom:30px;

		span{
			display: inline-block;
			position:relative;

			&:before{
				content:'';
				display: block;
				width:100vw;
				border:1px solid #CCC;
				left:100%;
				top:calc(50% - 1px);
				position:absolute;
				margin-left:20px;
			}
		}
	}
}



.detalhes-do-produto{
	h2{
		font-size:17px;
		@extend .ops-bld;
	}

	font-size:14px;

	.rating{
		@extend .text-muted;

		span{
			display: inline-block;
			margin-left:15px;
		}
	}

	.card{
		background: #EBEBEB;
		border-color: #EBEBEB;
		border-radius:0;

		& + .card{
			margin-top:15px;
		}
	}

	@include media-breakpoint-down(md){
		& > *{
			max-width:600px;
			margin-left:auto;
			margin-right:auto;
		}
		@for $i from 1 through 12{
			.xs-order-#{$i}{
				order: $i;
			}
		}
	}

	@include media-breakpoint-up(lg){

		.album{
			flex-wrap:wrap;

			.col{
				min-width:100%;
				padding-bottom:15px;
				padding-top:15px;
			}
		}
	}

	.input-group{
		.btn{
			border-color:#ccc;
			border-radius:0;
		}
	}

	lazyimage.img-thumbnail{
		display: inline-block;
		border:none;
	}
}

@mixin social-variant($bg){
	background: $bg;

	&:hover{
		background: darken($bg, 10%);
	}
}

.midias-sociais-square{
	.fa{
		padding:6px 12px;
		margin:10px 2px;
		color:#FFF;

		&:hover{
			text-decoration:none;
		}
	}

	.fa-facebook{
		@include social-variant(#3C5A9A);
	}

	.fa-twitter{
		@include social-variant(#00ADE7);
	}

	.fa-google-plus{
		@include social-variant(#ED4240);
	}

	.fa-pinterest-p{
		@include social-variant(#EE2D34);
	}

	.fa-linkedin{
		@include social-variant(#225982);
	}

	.fa-instagram{
		@include social-variant(#5440D5);
	}
}

#tab-detalhes-produto{
	.nav-tabs{
		.nav-item{
			margin-right:5px;
		}
		a{
			background: darken(#EBEBEB, 10%);
			border-color:transparent;

			&:hover{
				background: darken(#EBEBEB, 5%);
				color:#000;
			}
		}
		.active a,
		.active a:hover,
		.active a:focus{
			background: #EBEBEB;
		}
	}

	.tab-content{
		padding:15px;
		background: #EBEBEB;
	}
}

img{
	max-width: 100%;
	height: auto !important;
}

.avaliacoes{
	margin-top: 60px;

	h2{
		font-size: 25px;
		font-family: latoregular;
		font-weight: 500;
	}

	.stars{
		color: darken(#E5E5E5, 10%);
		flex-grow: 1;
	}

	.fa{
		position: relative;

		&.fullstar{
			color:#FF9134;
		}

		&.halfstar{
			&:after{
				content: "\f089";
				color:#FF9134;
				position:absolute;
				left:0;
			}
		}
	}

	.form-control{
		background: #EDEDEE;
	}
}

.nota{
	font-size: 30px;
	display: flex;
	flex-wrap: wrap;
	margin-bottom:40px;
	margin-top:40px;

	.num{
		@extend .ops-bld;
		padding-right: 15px;
	}

}

.client-avaliation{
	padding: 20px 0;
	border-top: 1px solid #CCC;

	.autor{
		font-weight: bold;
	}
}

.radio-customizado{
	input{
		display: none;
	}

	span{
		display: flex;
		align-items: center;
		padding-left:1.5em;
		position: relative;

		&:after{
			content:'';
			display: inline-block;
			width:1em;
			height:1em;
			border-radius: 100%;
			background: #4F4F4F;
			position: absolute;
			left:0;
		}

		&:before{
			content:'';
			display: inline-block;
			width:1em;
			height:1em;
			border-radius: 100%;
			position: absolute;
			left:0;
			border:2px solid #4F4F4F;
			background: materialColor('blue', 'blue');
			z-index: 2;
			transform: scale(0);
			transition: all 0.3s linear;
		}

		&:before,
		&:after{
			cursor: pointer;
		}
	}

	input:checked ~ span:before{
		transform: scale(1)
	}
}
#pdfviewer{
	height: 30rem;
}
.segura__btn__download{
	display: flex;
	justify-content:center;
	margin-bottom: 20px;

	button{
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: latoblack;
		font-size: 13px;
		line-height: 15px;
		background-color: #007bbe;
		height: 45px;
		color: #fff;
		max-width: 400px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		border: none;

		.download__icone{
			margin-right: 10px;
		}
	}
}

.rating__stars{
	color: #cacaca;
	i{
		position: relative;
	}
}
.rating__stars .fa-star:after {
  	position: absolute;
	color: #eaa931;
	left: 0;
}
.rating__stars[data-rating="0.5"] .fas:nth-child(1):after {
  	content: "";
}

.rating__stars[data-rating="1.5"] .fas:nth-child(2):after {
  	content: "";
}

.rating__stars[data-rating="2.5"] .fas:nth-child(3):after {
  	content: "";
}

.rating__stars[data-rating="3.5"] .fas:nth-child(4):after {
 	content: "";
}

.rating__stars[data-rating="4.5"] .fas:nth-child(5):after {
 	content: "";
}

.rating__stars[data-rating="5.5"] .fas:nth-child(6):after {
 	content: "";
}

.rating__stars[data-rating="1"] .fas:nth-child(1),
.rating__stars[data-rating="1.5"] .fas:nth-child(1) {
  	color: #eaa931;
}

.rating__stars[data-rating="2"] .fas:nth-child(1),
.rating__stars[data-rating="2"] .fas:nth-child(2),
.rating__stars[data-rating="2.5"] .fas:nth-child(1),
.rating__stars[data-rating="2.5"] .fas:nth-child(2) {
  	color: #eaa931;
}

.rating__stars[data-rating="3"] .fas:nth-child(1),
.rating__stars[data-rating="3"] .fas:nth-child(2),
.rating__stars[data-rating="3"] .fas:nth-child(3),
.rating__stars[data-rating="3.5"] .fas:nth-child(1),
.rating__stars[data-rating="3.5"] .fas:nth-child(2),
.rating__stars[data-rating="3.5"] .fas:nth-child(3) {
  	color: #eaa931;
}

.rating__stars[data-rating="4"] .fas:nth-child(1),
.rating__stars[data-rating="4"] .fas:nth-child(2),
.rating__stars[data-rating="4"] .fas:nth-child(3),
.rating__stars[data-rating="4"] .fas:nth-child(4),
.rating__stars[data-rating="4.5"] .fas:nth-child(1),
.rating__stars[data-rating="4.5"] .fas:nth-child(2),
.rating__stars[data-rating="4.5"] .fas:nth-child(3),
.rating__stars[data-rating="4.5"] .fas:nth-child(4) {
  	color: #eaa931;
}
.rating__stars[data-rating="5"] {
  	color: #eaa931;
}
.titulo__ministro{
	color: #565656;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 20px;
	span{
		font-family: latoblack;
	}
}
.segura__video{
	width: 100%;
	padding-top: 24px;
	.embed-responsive{
		&:before{
			padding-top: 72%;
		}
	}

}
.titulo__duvidas{
	font-size: 28px;
	line-height: 30px;
	color: #333333;
	margin-bottom: 10px;
}
.sub__duvidas{
	font-family: latoblack;
	font-size: 16px;
	line-height: 20px;
	color: #565656;
}
.duvida__form{
	padding-top: 36px;
}
.duvida__input{
	width: 100%;
	display: block;
	resize: none;
	border: 1px solid #d2d2d2;
	background-color: #eeeeee;
	padding: 15px;
	resize: none;
	&::placeholder{
		font-style: italic;
		color: #333333;
	}
}
.avaliacao__radio{
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
}
.row__custom{
	& > *{
		padding-top: 55px;
	}
}

.label__avaliacao{
	font-size: 15px;
	line-height: 15px;
	color: #000;
	position: relative;
	padding-left: 22px;
    margin-right: 10px;

	&:hover{
		cursor: pointer;

		&:before{
			opacity: 0.5;
		}
	}

	&:after{
		content: '';
		display: block;
		width: 16px;
		min-width: 16px;
		height: 16px;
		border: 2px solid #000;
		border-radius: 50%;
		position: absolute;
		left: 0px;
		top: 50%;
        transform: translateY(-50%);
		transition: all 0.3s linear;
	}
    &:before{
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        background-color: #000;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        left: 4px;
        transition: all 0.3s linear;
        opacity: 0;
        position: absolute;
    }
}
.radio__avaliacao{
	position: fixed;
	left: -100vw;

	&:checked{
		& ~ .label__avaliacao{
			&:before{
				opacity: 1;
			}
		}
	}
}
.pesquisa__treinamento{
	display: flex;
	height: 60px;
	align-items: center;
	border: 1px solid #c9c9c9;
	margin-bottom: 12px;

	& > *{
		display: flex;
		height: 58px;
		align-items: center;
	}
	input{
		width: 100%;
		background-color: #eeeeee;
		padding: 16px;
		border: none;
		height: 58px;
		color: #333333;

		&::placeholder{
			font-style: italic;
			color: #333333;
		}
	}
	button{
		width: 50px;
		min-width: 50px;
		justify-content: center;
		border: none;
		color: #333333;
		font-size: 20px;
		line-height: 20px;
	}
}
.pesquisa__aula{
	margin-bottom: 12px;
	button{
		display: flex;
		height: 60px;
		align-items: center;
		border: 1px solid #c9c9c9;
		padding: 16px;
		color: #333333;
		width: 100%;
		justify-content: space-between;
		font-style: italic;

		&:after{
			content: '';
			position: relative;
			display: block;
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-top: 7px solid #333333;
		}
	}
	.aula__item{
		border: 1px solid #c9c9c9;
		background-color: #dcdcdc;
		border-top: none;
		height: 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 13px;
		line-height: 15px;
		font-style: italic;
		padding: 10px 20px;
		position: relative;

		&:after{
			width: 22px;
			height: 20px;
			content: '';
			display: block;
			mask-image: url(../imagens/estrutural/timer.png);
			mask-repeat: no-repeat;
			mask-size: auto;
			mask-position: center center;
			background-color: currentColor;
		}

		.segura__aula__span{
			display: flex;
			align-items: center;

			.concluido{
				background-color: #007db8;
				color: #fff;
				padding: 2px;
				display: inline-block;
				margin-left: 5px;
				border-radius: 2px;
			}
		}
		&.active{
			color: #007bbe;
		}
	}
}
.segura__avaliacao{
	display: flex;
	align-items: center;
	margin-top: 30px;
	margin-bottom: 10px;

	.rating__stars{
		margin-right: 5px;
	}
}
.avaliacao__titulo{
	font-family: latoblack;
	color: #333333;
	font-size: 22px;
	line-height: 29px;
}
.avaliacao__sub{
	margin-bottom: 20px;
}
.btn__enviar{
	display: inline-flex;
	min-width: 100px;
	height: 45px;
	background-color: #007bbe;
	color: #fff;
	border: none;
	margin-top: 20px;
	align-items: center;
	justify-content: center;
	padding-bottom: 2px;
	transition: all 0.3s linear;

	&:hover{
		background-color: darken(#007bbe, 10%);
		color: #fff;
		text-decoration: none;
	}
}
.row__custom{
	@include media-breakpoint-up (lg) {
		display: grid;
		grid-template-columns: calc(calc(calc(7/12) * 100%) - 15px) calc(calc(calc(5/12) * 100%) - 15px);
		column-gap: 30px;

		&.alt{
			.col__2{
				order: 1;
			}
			.col__3{
				order: 3;
			}
			.col__4{
				order: 2;
				padding-top: 120px;
			}
		}
	}
}
