$icons: (
    'boleto': 0 0 55px 37px,
    'br': 0 -37px 48px 33px,
    'en': 0 -70px 48px 33px,
    'es': 0 -103px 49px 33px,
    'nao-pago': 0 -136px 24px 24px,
    'pago': 0 -160px 28px 24px,
);

.icon{
    display:inline-block;
    background-image: url(#{$imgs}/sprite.png);
    background-repeat:no-repeat;

    &.block{
        display:block;
    }
}

@mixin sprites($icons) {
    @each $icon,$data in $icons{
        .#{$icon}{
            width: nth($data,3);
            height: nth($data,4);
            background-position: #{nth($data,1)} #{nth($data,2)};
        }
    }
}

@include sprites($icons);