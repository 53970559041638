.card-produto{
	width:270px;
	max-width:100%;
	margin-left: auto;
	margin-right: auto;
	font-size:14px;
	text-align:center;
	position:relative;

	.foto{
		margin-bottom:0;
		padding-bottom:100%;
		position:relative;
		background:#FFF;

		lazyimage{
			display:flex;
			overflow:hidden;
			justify-content:center;
			align-items:center;
			position:absolute;
			width:100%;
			height:100%;
			top:0;
			left:0;
			background: url(#{$imgs}/loading.svg) center center no-repeat #FFF;

			&[loaded="true"]{
				background-image:none !important;
			}
		}
	}

	.card-body{
		padding-top:3px;

		&:before{
			content:'';
			height:4px;
			background: #E0E0E0;
			margin-bottom:10px;
			display: block;
		}
	}


	.card-title{
		margin-top:0;
		margin-bottom:10px;
		font-size:15px;
		color: #464646;
	}

	.rating{
		color:#666;
		margin-bottom:10px;

		.active{
			color:materialColor('orange','orange');
		}
	}
	
	.close{
		cursor:pointer;
	}

	.description{
		position:absolute;
		display: none;

		width:100%;
		height: 100%;

		background: rgba(#007DB8, 0.8);
		color:#FFF;

		top:0;
		left:0;
		padding:10px;

		overflow:auto;
	}

	.balao{
		background:#FFF;
		border-radius:10px;
		padding:10px;
		margin-bottom:15px;
		color:#000;
		font-size:12px;
		position:relative;
		filter: drop-shadow(0 0 4px rgba(#000,0.4));

		&:before{
			content:'';
			display: block;
			border-left:10px solid transparent;
			border-right:10px solid transparent;
			border-top:10px solid #FFF;
			position:absolute;
			left:15px;
			top:100%;
		}
	}

	.preco{
		margin-bottom:10px;

		small{
			display: block;
			font-size:13px;
		}

		span{
			font-size:40px;
		}
	}

	.buttons{
		margin-top:20px;

		.btn{
			margin:0 10px;
		}
	}
}