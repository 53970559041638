.buscas{
	position:relative;
	.form-control{
		border-color:transparent;
		border-radius:0;
		width:0;
		padding-left:0;
		padding-right:0;
		transition:width 0.4s linear;
	}

	&.focus .form-control{
		width:180px;
		padding-left:10px;
		padding-right:10px;
	}

	.btn{
		width:42px;
		position:relative;
		z-index:0;
		cursor:pointer;
	}
}