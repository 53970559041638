.btn-carrinho{
	position:relative;

	svg{
		margin-right:7px;
		path{
			fill:#FFF;
		}
	}
	
	&:hover{
		.drop-carrinho{
			display: block;
			animation:fadeIn 0.3s linear;
		}
	}

	.drop-carrinho{
		position:absolute;
		border:1px solid #CCC;
		color:#000;
		background:#FFF;
		padding:10px;
		text-align:center;
		white-space:nowrap;
		z-index:2;
		top:100%;
		right:0;
		display: none;

		&:before{
			content:'';
			border-left:10px solid transparent;
			border-right:10px solid transparent;
			border-bottom:10px solid #FFF;
			right:10px;
			bottom:100%;
			position:absolute;
		}

		.total{
			padding:10px 0;
		}
	}
}