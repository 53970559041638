$imgs: '../imagens/estrutural';
$fonts: '../fonts';

$theme: #007DB8;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px
);