#banner{
	position:relative;
	overflow:hidden;

	.carousel-inner{
		position:relative;
		z-index:1;

		.active{
			animation:fadeIn 0.6s linear;
		}
	}

	.ctrls{
		position:absolute;
		width:100%;
		height:100%;
		display:flex;
		align-items:center;
		justify-content:center;
		left:0;
		top:0;

		.clearfix{
			width:1170px;
			max-width:100%;
			padding-left:15px;
			padding-right:15px;
		}

		a{
			position:relative;
			z-index:2;
			filter:drop-shadow(0 0 4px rgba(#000,0.4));

			svg polygon{
				fill:#FFF;
			}

			&:hover{
				svg polygon{
					fill: $theme;
				}
			}

			&.prev{
				float:left;
			}

			&.next{
				float:right;
			}
		}

		// @include media-breakpoint-down(md){
			svg{
				width: 23px;
				height: 47px;
			}
		// }
	}
}

#pq-insulcare{
	.card{
		.card-body{
			padding-top:10px;
			padding-bottom:10px;
			background: #00B0ED;
		}

		lazyimage{
			display: block;
			overflow:hidden;
		}

		img{
			transition: all 0.3s linear;
		}

		a:hover{
			background:#000;
			img{
				transform: scale(1.1);
				opacity:0.6;
			}
		}
	}

	@include media-breakpoint-only(sm){
		.dual-figure{
			display:flex;

			.picture{
				flex:1;
			}
		}
	}
}

#insulcare-revuluciona{
	color:#FFF;
	font-size:24px;
	padding-top:200px;
	padding-bottom:80px;

	min-height:660px;

	@include media-breakpoint-up(lg){
		display:flex;
		align-items:center;
	}

	h2{
		font-size:25px;
		margin-bottom:30px;
	}
}

#o-que-nossos-clientes-falam{
	padding:80px 0;
	color:#FFF;
}

.avatar-depoent{
	text-align: center;

	lazyimage{
		width:100px;
		height:100px;
		margin-left:auto;
		margin-right:auto;
		border-radius:100%;
		overflow:hidden;
		background: #FFF;
		display:flex;
		align-items:center;
		justify-content:center;
		margin-bottom:10px;
		box-shadow:0 0 4px rgba(#000,0.8);
	}

	figcaption{
		span{
			display: block;
		}

		.nome{
			color: #FFF;
			font-weight:bold;
			font-size:16px;
		}

		.detalhe{
			font-size:13px;
			color: #FFF;
		}
	}
}

.ctrls-dep-carousel{
	display: inline-block;
	width:60px;
	height:60px;
	line-height:60px;
	border:2px solid #FFF;
	border-radius:100%;
	text-align:center;
	margin-top:40px;
	font-size:20px;

	&:hover{
		color: #fff;
		background: rgba(#FFF,0.2);
	}
}

.chmd-index{
	color: #989898;
	font-size: 28px;
	font-style: italic;
	text-align:center;
}

.newsletter{
	background: #616fb0;
	color:#FFF;
	padding-top:40px;
	font-size:16px;

	.form-control,
	.btn{
		border-radius:30px;
		color: #fff;
	}
}
.header__treinamento{
	padding: 30px 20px 20px 20px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	color: #fff;
}
