.rodape{
	background: #D6D6D6;
	padding-top:30px;

	a{
		color: #363636;
	}

	.box__interesse{
		background-image: url(../imagens/conteudo/mapa-brasil.png);
		background-position: center top;
		background-repeat: no-repeat;
	}

	.btn__interesse {
		width: 200px;
		height: 40px;
		border-radius: 40px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
		background: #616fb0;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: auto;
		margin-right: auto;
		font-size: 12px;
		margin-top: 40px;

		&:hover{
			color: #fff;
		}
	}

	.rodape__btn-whatsapp{
		position:fixed;
		bottom:15px;
		left:15px;
		z-index:400;
		background-color:#4caf50;
		border-radius:100%;
		color:#FFF;
		font-size:40px;
		width:(60 / 40) * 1em;
		height:(60 / 40) * 1em;
		line-height:(60 / 40) * 1em;
		display:inline-flex;
		align-items:center;
		justify-content:center;
		box-shadow:0 0 8px rgba(#000,0.5);
		transition:background-color 0.6s linear;

		&:hover{
			background-color:darken(#4caf50,10%);
			color:#FFF;
		}

		&:after{
			content:'';
			display:block;
			position:absolute;
			top:calc(50% - 7px);
			left:calc(50% - 7px);
			border-radius:100%;
			z-index:-2;
			width:15px;
			height:15px;
			background-color:#4caf50;
			animation:explodeMidiaMenor 4s infinite;
		}

	}

	@keyframes explodeMidiaMenor{

		0%{
			opacity:1;
			transform:scale(1);
		}

		100%{
			opacity:0;
			transform:scale(7);
		}

	}

	ul{
		list-style:none;
		padding-left:0;

		li {
			margin-bottom:5px;
		}

	}

	a:hover,
	.active a{
		text-decoration: none;
		color: $theme;
	}

	.social-midia{
		font-size:25px;

		a{
			margin:10px;
		}
	}
}

.creditos{
	padding:10px 0;
	background:#FFF;
	font-size:13px;

	.gv8{
		display: block;
		width:66px;
	}

	@include media-breakpoint-down(sm){
		text-align:center;

		.gv8{
			margin-left:auto;
			margin-right:auto;
			margin-top: 10px;
			width:90px !important;
		}
	}

	@include media-breakpoint-up(md){
		.col-md-6:last-child{
			display:flex;
			justify-content:flex-end;

			.gv8{
				margin-left:10px;
			}
		}
	}
}
